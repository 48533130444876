import { page } from '$app/stores'
import { derived } from 'svelte/store'

const originRegexp = /^(.+)-(vaccinated|unvaccinated)$/
const destinationRegexp = /^(.+)-from-(.+)-(vaccinated|unvaccinated)$/

type RestrictionRouteStore = {
  origin: string
  destination: string
  isVaccinated: boolean
}

const store = derived([page], ([$page]): RestrictionRouteStore => {
  if (originRegexp.test($page.params.originAndVaccination)) {
    // Origin page
    const [, origin, vaccination] = $page.params.originAndVaccination?.match(originRegexp)
    return { origin, isVaccinated: vaccination == 'vaccinated', destination: null }
  } else if (originRegexp.test($page.params.destinationFromOriginAndVaccination)) {
    // Destination page
    const [, origin, destination, vaccination] =
      $page.params.destinationFromOriginAndVaccination.match(destinationRegexp)
    return { origin, isVaccinated: vaccination == 'vaccinated', destination }
  }
  return { origin: null, destination: null, isVaccinated: null }
})

export const restrictionRoute = store
